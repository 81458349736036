import { BFormSelect } from 'bootstrap-vue'
export default {
    component: { BFormSelect },
    props: {
        event_data: {
            default: null,
        },
    },
    data() {
        return {
            expdata: null,
            reminder_switch: false,
            displayModal: false,
            saveReminderTimeSettings: [],
            reminderHour: 0,
        }
    },
    created() {
        if (this.event_data) {
            this.expdata = this.event_data
        } else {
            let data = this.$store.state.auth.exp_data
            let merged_data = {
                ...data.promotions,
                ...data.events,
                ...data.offers,
            }
            let id = this.$route.params.id
            let type = this.$route.params.type
            for (const value of Object.values(merged_data)) {
                if (id == value.id && type == value.type) {
                    this.expdata = value
                    console.log(this.expdata)
                }
            }
        }
        this.fetchConfig()
        if (this.expdata.type == 'events') {
            this.setReminderSwitch()
        }
    },
    mounted() {
        this.$http
            .post('/track-member-activity', {
                member_activity_type: 'VIEW',
                target_page_type: this.expdata.type.slice(0, -1),
                target_object_id:
                    this.expdata.type == 'offers'
                        ? this.expdata.id
                        : this.expdata.occurence_id,
            })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    },
    computed: {
        activeUser() {
            return this.$store.getters['auth/getAuthenticatedUser']
        },
        isAttending() {
            if (this.activeUser && this.expdata.type == 'events') {
                const ff = this.activeUser.my_attending_events.find(
                    (myEvent) =>
                        myEvent.occurence_id === this.expdata.occurence_id
                )
                return ff
            }
            return null
        },
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('paymentmodal')
        },
        setReminder(val) {
            this.$http
                .post('/save-reminder', {
                    occurence_id: this.expdata.occurence_id.toString(),
                    reminder_hours_before_occurence: val,
                    mode: 'reminder',
                })
                .then((response) => {
                    if (response.status == 200) {
                        this.$helper.showMessage(response.data.message)
                        this.$store.dispatch(
                            'auth/attempt',
                            this.$store.state.token
                        )
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        removeReminder() {
            this.$http
                .post('/remove-reminder', {
                    occurence_id: this.expdata.occurence_id.toString(),
                    mode: 'reminder',
                })
                .then((response) => {
                    if (response.status == 200) {
                        this.$helper.showMessage(response.data.message)
                        this.$store.dispatch(
                            'auth/attempt',
                            this.$store.state.token
                        )
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        setReminderSwitch() {
            let reminder_events = this.activeUser.my_reminder_events
            if (reminder_events) {
                for (let i in reminder_events) {
                    if (
                        reminder_events[i].occurence_id ==
                        this.expdata.occurence_id
                    ) {
                        this.reminder_switch = true
                        this.reminderHour =
                            reminder_events[i].reminder_hours_before_occurence
                    }
                }
            }
        },
        async fetchConfig() {
            try {
                const resp = await this.$http.get('/config')
                this.saveReminderTimeSettings = [
                    // {label: 'No reminder', value_in_hours: 0, disabled: true},
                    ...resp.data.save_reminder_time_settings,
                ]
            } catch (e) {
                console.error(e)
                this.$bvToast.toast('Oops! Something went wrong', {
                    title: `Error`,
                    variant: `danger`,
                    solid: true,
                })
            }
        },
        changeSwitch() {
            if (this.reminder_switch) {
                this.setReminder()
                this.$store.dispatch('auth/attempt', this.$store.state.token)
            } else {
                this.removeReminder()
                this.$store.dispatch('auth/attempt', this.$store.state.token)
            }
        },
        async attendEvent() {
            try {
                if (!this.isAttending) {
                    const resp = await this.$http.post('accept-invite', {
                        occurence_id: this.expdata.occurence_id,
                        name: this.activeUser.name,
                        email: this.activeUser.email,
                        phone_number: this.activeUser.phone_number,
                        member_id: '' + this.activeUser.member_id,
                        logged_in: 1,
                    })
                    if (resp.status === 200 && resp.data.status) {
                        this.$store.dispatch(
                            'auth/attempt',
                            this.$store.state.token
                        )
                        this.$helper.showMessage(resp.data.message)
                        if (
                            this.expdata.event_attributes.price &&
                            this.expdata.event_attributes.price > 0
                        ) {
                            this.displayModal = true
                        }
                    }
                } else {
                    this.$helper.showMessage(
                        'You are already attending this event',
                        'warning'
                    )
                }
            } catch (e) {
                console.error(e)
                this.$helper.showMessage('Oops! Something went wrong', 'danger')
            }
        },
    },
}
